import { createGlobalStyle } from "styled-components";

import Sensation from "./fonts/sansation.regular.ttf";

const GlobalStyles = createGlobalStyle`
    //@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap"');
    @font-face {
        font-family: 'Sensation';
        src: url(${Sensation});
    }
    body{
        font-family: Sensation;
        margin: 0px;
        padding: 0px;
        color: #FFE8D1;
        font-display: swap;
    }
`;

export default GlobalStyles;
