import book from "./book.svg";
import car from "./car.svg";
import certificate from "./certificate.svg";
import envelope from "./envelope.svg";
import feather from "./feather.svg";
import gear from "./gear.svg";
import laptop from "./laptop.svg";
import law from "./law.svg";
import letter from "./letter.svg";
import phone from "./phone.svg";
import scroll from "./scroll.svg";
import greenPhone from "./greenPhone.svg";
import greenEnvelope from "./greenEnvelope.svg";

const Icons = {
    book,
    car,
    certificate,
    envelope,
    feather,
    gear,
    laptop,
    law,
    letter,
    phone,
    scroll,
    greenPhone,
    greenEnvelope,
};

export default Icons;
