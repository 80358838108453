import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Styles from "./styles";
import MobileMenu from "../MobileMenu";

const { Wrapper } = Styles;

const MobileMenuBtn = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const ToggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    useEffect(() => {
        console.log(isMenuActive);
    }, [isMenuActive]);

    return (
        <>
            <Wrapper onClick={ToggleMenu}>
                {isMenuActive === true ? (
                    <Icon icon="entypo:cross" />
                ) : (
                    <Icon icon="heroicons-solid:menu-alt-3" />
                )}
            </Wrapper>
            <MobileMenu
                isMenuActive={isMenuActive}
                setIsMenuActive={setIsMenuActive}
            />
        </>
    );
};

export default MobileMenuBtn;
