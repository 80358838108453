import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        position: fixed;
        width: 48px;
        height: 48px;
        top: 6px;
        right: 10px;
        z-index: 100;
        transition: 0.3s;
        & > svg {
            width: 100%;
            height: 100%;
        }
        &:hover {
            cursor: pointer;
            color: gold;
        }
        @media (min-width: 891px) {
            display: none;
        }
    `,
};

export default Styles;
