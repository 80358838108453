import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as GatsbyLink } from "gatsby";
import IMobileMenu from "./types";

const Styles = {
    Wrapper: styled.div<IMobileMenu>`
        display: ${({ isMenuActive }) => (isMenuActive ? "flex" : "none")};
        position: fixed;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #253e38, #496860 100%);
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 70;
    `,
    NavLink: styled(Link)`
        color: ${(props) => props.theme.color.cream};
        margin: 10px 0 10px 0;
        font-size: 18px;
        transition: 0.3s;
        &:hover {
            color: gold;
            cursor: pointer;
        }
    `,
    LangBtn: styled(GatsbyLink)`
        color: ${(props) => props.theme.color.cream};
        margin: 15px 0 10px 0;
        font-size: 18px;
        transition: 0.3s;
        &:hover {
            color: gold;
        }
    `,
};

export default Styles;
