import styled from "styled-components";

interface ISVGIcon {
    width: string;
    height: string;
}

export const Container = styled.div`
    width: 1200px;
    margin-left: 15px;
    margin-right: 15px;
    @media (max-width: 1220px) {
        width: 100%;
    }
`;

export const SVGIcon = styled.img<ISVGIcon>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`;

export const Text = styled.p`
    color: ${(props) => props.theme.color.cream};
    font-size: ${(props) => props.theme.fontSize.normal};
    font-style: normal;
    margin: 0;
    padding: 10px;
`;
