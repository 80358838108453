import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as GatsbyLink } from "gatsby";

const Styles = {
    NavWrapper: styled.div`
        width: 100%;
        height: 120px;
        position: fixed;
        z-index: 50;
        display: flex;
        justify-content: center;
        align-items: center;
       // background-color: ${(props) => props.theme.color.green};
        background: linear-gradient(90deg, #253e38, #496860 100%);
        @media (max-width: 890px) {
            height: 60px;
        }
    `,
    NavContainer: styled.div`
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 890px) {
            display: none;
        }
    `,
    MobileNav: styled.div``,
    NavSide: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `,
    LangBtn: styled.div`
        font-size: 18px;
    `,
    NavLink: styled(Link)`
        color: ${(props) => props.theme.color.cream};
        font-size: ${(props) => props.theme.fontSize.normal};
        font-style: normal;
        margin: 0;
        padding: 10px;
        transition: 0.3s;
        &:hover {
            color: gold;
            cursor: pointer;
        }
    `,
    LangLink: styled(GatsbyLink)`
        color: ${(props) => props.theme.color.cream};
        font-size: ${(props) => props.theme.fontSize.normal};
        font-style: normal;
        margin: 0;
        padding: 10px;
        transition: 0.3s;
        text-decoration: none;
        &:hover {
            color: gold;
            cursor: pointer;
        }
    `,
};

export default Styles;
